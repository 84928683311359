.config_visual_editor_object_picker__grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.config_visual_editor_object_picker__item {
    flex: 1 0 calc(33% - 1rem);
    max-width: calc(33% - 1rem);
    box-shadow: var(--nds-box-shadow-drop_stack_1);
    padding: var(--nds-l-200);
}
