.config_visual_editor__workspace {
    padding: var(--nds-l-200);
    background-color: var(--nds-color-core_grey_10);
    min-height: 100vh;
}

.config_visual_editor__resource_list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.config_visual_editor__node {
    flex: 1 0 100%;
}
@media screen and (min-width: 33rem) {
    .config_visual_editor__node {
        flex: 1 0 calc(50% - 1rem);
        max-width: 50%;
    }
}
@media screen and (min-width: 71rem) {
    .config_visual_editor__node {
        flex: 1 0 calc(375px - 1rem);
        max-width: 375px;
        width: 100%;
    }
}

.config_visual_editor__text_editor {
    height: 100%;
}
.config_visual_editor__code textarea,
.config_visual_editor__code .ncinputtext__control_row__control_container {
    height: calc(100vh - 172px);
    border-radius: 0 !important;
}
