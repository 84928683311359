.input_list__list_container {
    border: 1px solid var(--nds-color-core_grey_200);
    border-radius: var(--nds-l-utilities_50);
    padding: var(--nds-l-200);
    margin-bottom: var(--nds-l-100);
}

.input_list__list_item_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--nds-l-utilities_50);
    box-shadow: var(--nds-box-shadow-drop_stack_2);
    padding: var(--nds-l-200);
    margin-bottom: var(--nds-l-100);
}


.input_list__description_field {
    text-align: left;
    width: 100%;
}
