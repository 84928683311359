.segmented_control {
    border: 1px solid var(--nds-color-semantic_primary_600);
    border-radius: var(--nds-l-utilities_50);
    overflow: hidden;
}
.segmented_control__button {
    padding: var(--nds-l-100) var(--nds-l-200);
    border: none;
    width: 50%;
    background: var(--nds-color-core_white);
    color: var(--nds-color-semantic_primary_600); 
    box-shadow: none;
    cursor: pointer;
    font: var(--nc-t-sub_text_medium);
}
.segmented_control__button--active {
    background: var(--nds-color-semantic_primary_600); 
    color: var(--nds-color-core_white);
}
